import { Header } from '../components/header';
import { Footer } from '../components/footer';

export const Download = () => {
  return (
    <div
      style={{
        backgroundColor: '#E8E8E8',
        padding: '0 20px',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 40,
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Header />
        <div
          style={{
            maxWidth: 800,
          }}
        >
          <h1 style={{ fontSize: 48 }}>Download</h1>
          <ul>
            <li>
              <p
                style={{
                  lineHeight: 1.35,
                }}
              >
                For Ubuntu, Debian:{' '}
                <a
                  href='https://github.com/CurlerRoo/CurlerRoo/releases/download/v0.2.2/curlerroo_0.2.2_amd64.deb'
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  curlerroo_0.2.2_amd64.deb
                </a>
              </p>
            </li>
            <li>
              <p
                style={{
                  lineHeight: 1.35,
                }}
              >
                For Fedora, CentOS:{' '}
                <a
                  href='https://github.com/CurlerRoo/CurlerRoo/releases/download/v0.2.2/curlerroo-0.2.2.x86_64.rpm'
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  curlerroo-0.2.2.x86_64.rpm
                </a>
              </p>
            </li>
            <li>
              <p
                style={{
                  lineHeight: 1.35,
                }}
              >
                For MacOS:{' '}
                <a
                  href='https://github.com/CurlerRoo/CurlerRoo/releases/download/v0.2.2/CurlerRoo-0.2.2-universal.dmg'
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  CurlerRoo-0.2.2-universal.dmg
                </a>
              </p>
            </li>
          </ul>
          <p
            style={{
              lineHeight: 1.35,
            }}
          >
            All releases are available on our{' '}
            <a
              href='https://github.com/CurlerRoo/CurlerRoo/releases'
              style={{
                fontWeight: 'bold',
              }}
            >
              GitHub releases page
            </a>
            .
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
