import { Header } from '../components/header';
import { Footer } from '../components/footer';
import { useNavigate } from 'react-router-dom';

export const Home = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        backgroundColor: '#E8E8E8',
        padding: '0 20px',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 40,
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Header />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ height: 100 }} />
          <img
            src='./logo256.png'
            className='App-logo'
            alt='logo'
            style={{
              width: 180,
            }}
          />
          <h1
            style={{
              fontSize: 48,
            }}
          >
            A powerful text based REST Client
          </h1>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
            width: '100%',
            maxWidth: 640,
            columnGap: 20,
            rowGap: 20,
            justifyItems: 'center',
          }}
        >
          <button
            style={{
              width: 200,
              height: 50,
              borderRadius: 10,
              border: 'none',
              color: '#001a1a',
              fontWeight: 'bold',
              fontSize: 18,
              cursor: 'pointer',
              border: '2px solid #001a1a',
            }}
            onClick={() =>
              window.open(
                'https://github.com/CurlerRoo/CurlerRoo/releases/download/v0.2.2/CurlerRoo-0.2.2-universal.dmg',
              )
            }
          >
            Download for Mac
          </button>
          <button
            style={{
              width: 200,
              height: 50,
              borderRadius: 10,
              border: 'none',
              color: '#001a1a',
              fontWeight: 'bold',
              fontSize: 18,
              cursor: 'pointer',
              border: '2px solid #001a1a',
            }}
            onClick={() => navigate('/download')}
          >
            Download for Linux
          </button>
          <button
            style={{
              width: 200,
              height: 50,
              borderRadius: 10,
              border: 'none',
              color: '#001a1a',
              fontWeight: 'bold',
              fontSize: 18,
              cursor: 'pointer',
              border: '2px solid #001a1a',
            }}
            onClick={() => {
              window.open('https://app.curlerroo.com', '_blank');
            }}
          >
            Use Web App
          </button>
        </div>
        <div style={{ height: 40 }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h1>Powerful yet simple</h1>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row-reverse',
              gap: '20px 100px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                borderRadius: 10,
                overflow: 'hidden',
                maxWidth: 467,
                border: '1px solid #888',
              }}
            >
              <img
                src='./powerful-yet-simple.png'
                alt='screenshot'
                style={{
                  width: '100%',
                }}
              />
            </div>
            <div style={{ flex: 1, maxWidth: 433, minWidth: 300 }}>
              <p
                style={{
                  lineHeight: 1.5,
                }}
              >
                CurlerRoo supports custom variables and scripts before and after sending the
                request. This allows you to easily create complex requests and automate your
                workflow.
              </p>
              <p
                style={{
                  lineHeight: 1.5,
                }}
              >
                The scripts are written in JavaScript, so you can use all the power of the language
                to manipulate the request and response data.
              </p>
            </div>
          </div>
        </div>
        <div style={{ height: 60 }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h1>Intuitive by design, powerful in action</h1>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              gap: '20px 100px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                borderRadius: 10,
                overflow: 'hidden',
                maxWidth: 467,
                border: '1px solid #888',
              }}
            >
              <img
                src='./intuitive.png'
                alt='screenshot'
                style={{
                  width: '100%',
                }}
              />
            </div>
            <div style={{ flex: 1, maxWidth: 433, minWidth: 300 }}>
              <p
                style={{
                  lineHeight: 1.5,
                }}
              >
                Scripts are powerful, but you don't have to write them. You can create variables
                from responses and embed them in the next request with just a few clicks.
              </p>
            </div>
          </div>
        </div>
        <div style={{ height: 60 }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h1>See more. Switch less.</h1>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row-reverse',
              gap: '20px 100px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                borderRadius: 10,
                overflow: 'hidden',
                maxWidth: 467,
                border: '1px solid #888',
              }}
            >
              <img
                src='./see-more-switch-less.png'
                alt='screenshot'
                style={{
                  width: '100%',
                }}
              />
            </div>
            <div style={{ flex: 1, maxWidth: 433, minWidth: 300 }}>
              <p
                style={{
                  lineHeight: 1.5,
                }}
              >
                Multiple requests could be displayed in a single window. Thanks to the compact of
                cURL commands.
              </p>
              <p
                style={{
                  lineHeight: 1.5,
                }}
              >
                This allows you to easily execute multiple chained requests. For example, you can
                create a request that logs in, then use the response to create a request that
                fetches data, and then use that data to create a request that updates something. All
                in a single window.
              </p>
            </div>
          </div>
        </div>
        <div style={{ height: 60 }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h1>Syntax Highlighting & Suggestions</h1>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              gap: '20px 100px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                borderRadius: 10,
                overflow: 'hidden',
                maxWidth: 467,
                border: '1px solid #888',
              }}
            >
              <img
                src='./autocompletion.png'
                alt='screenshot'
                style={{
                  width: '100%',
                }}
              />
            </div>
            <div style={{ flex: 1, maxWidth: 433, minWidth: 300 }}>
              <p
                style={{
                  lineHeight: 1.5,
                }}
              >
                Struggle to remember exact cURL syntax? CurlerRoo's intelligent syntax highlighting
                and suggestion features make writing and understanding cURL commands a breeze. Our
                editor guides you, reducing errors and improving your workflow.
              </p>
            </div>
          </div>
        </div>
        <div style={{ height: 60 }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h1>Organize and Run with Ease</h1>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'column',
              gap: '20px 100px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                borderRadius: 10,
                overflow: 'hidden',
                border: '1px solid #888',
                maxWidth: '100%',
              }}
            >
              <img
                src='./overrall.png'
                alt='screenshot'
                style={{
                  width: '100%',
                }}
              />
            </div>
            <div style={{ flex: 1, maxWidth: 433, minWidth: 300 }}>
              <p
                style={{
                  lineHeight: 1.5,
                }}
              >
                By making the GUI intuitive and compact. CurlerRoo feels organized and doesn't get
                lost even if you have so many requests.
              </p>
            </div>
          </div>
        </div>
        <div style={{ height: 60 }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h1>For the Developer Who Values Control</h1>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'column',
              gap: '20px 100px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{ flex: 1, maxWidth: 600 }}>
              <p
                style={{
                  lineHeight: 1.5,
                }}
              >
                CurlerRoo is for developers who love having control over their tools. If you prefer
                text commands over clicking through buttons and menus, this is the app for you.
                Experience the freedom and power of manipulating your API requests directly, with
                all the support you need for efficient coding. Join the CurlerRoo community today
                and redefine your API interaction experience. It's not just about making requests;
                it's about mastering them.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: 40 }} />
      <Footer />
    </div>
  );
};
