import { Header } from '../components/header'
import { Footer } from '../components/footer'

export const Contact = () => {
  return (
    <div
      style={{
        backgroundColor: '#E8E8E8',
        padding: '0 20px',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 40,
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Header />
        <div
          style={{
            maxWidth: 800,
          }}
        >
          <h1 style={{ fontSize: 48 }}>Contact</h1>
          <p
            style={{
              lineHeight: 1.35,
            }}
          >
            If you have any questions, suggestions or feedback, please feel free to reach out to us
            at <a href='mailto:pclover.exe@gmail.com'>pclover.exe@gmail.com</a>
          </p>
          <p>
            You can also create an issue on our{' '}
            <a href='https://github.com/CurlerRoo/CurlerRoo/issues'>GitHub repository</a>
          </p>
          <p>We'd love to hear from you!</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}
