import { Header } from '../components/header'
import { Footer } from '../components/footer'

export const About = () => {
  return (
    <div
      style={{
        backgroundColor: '#E8E8E8',
        padding: '0 20px',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 40,
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Header />
        <div
          style={{
            maxWidth: 800,
          }}
        >
          <h1 style={{ fontSize: 48 }}>About CurlerRoo</h1>
          <h1>Why?</h1>
          <p
            style={{
              lineHeight: 1.35,
            }}
          >
            There are many REST clients out there, but almost all of them feel awkward and clunky to
            use thanks to lots of input fields and buttons.
          </p>
          <p
            style={{
              lineHeight: 1.35,
            }}
          >
            CurlerRoo is a cURL based REST client that allows you to write your requests in a simple
            and intuitive way. It's like writing a cURL command, but with a GUI that helps you with
            the syntax.
          </p>
          <h1>The goal</h1>
          <p
            style={{
              lineHeight: 1.35,
            }}
          >
            By embracing the power of cURL, we've created a tool that allows you to directly work
            with cURL requests and organized the them in the most simple possible way: plain text
            files!
          </p>
          <p
            style={{
              lineHeight: 1.35,
            }}
          >
            We created CurlerRoo with a vision to make it easier for developers to write and test
            REST APIs.
          </p>
          <h1>Commitment to Excellence</h1>
          <p
            style={{
              lineHeight: 1.35,
            }}
          >
            At CurlerRoo, we strive for excellence in every line of code and every design element.
            Our founder's commitment to delivering high-quality, user-friendly software is evident
            in every aspect of our product. We understand the value of your time and the importance
            of a seamless user experience.
          </p>
          <h1>Join Us on the Journey</h1>
          <p
            style={{
              lineHeight: 1.35,
            }}
          >
            Whether you're a seasoned professional or a tech enthusiast, we invite you to join us on
            this exciting journey. As we continue to evolve and enhance CurlerRoo, your feedback and
            support play a crucial role in shaping the future of our software.
          </p>
          <p
            style={{
              lineHeight: 1.35,
            }}
          >
            Thank you for choosing CurlerRoo. Together, let's explore the endless possibilities that
            technology has to offer.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}
