import { useNavigate } from 'react-router-dom';
import GitHubButton from 'react-github-btn';

export const Header = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: '100%',
        height: 60,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          maxWidth: 1200,
          flex: 1,
          gap: 40,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: 24,
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('/');
            }}
          >
            CurlerRoo
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: '20px 40px',
            padding: '20px 0',
          }}
        >
          <span
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('/about');
            }}
          >
            ABOUT
          </span>
          <span
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('/download');
            }}
          >
            DOWNLOAD
          </span>
          <span
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('/screenshots');
            }}
          >
            SCREENSHOTS
          </span>
          <span
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('/contact');
            }}
          >
            CONTACT
          </span>
          <GitHubButton
            href='https://github.com/CurlerRoo/CurlerRoo'
            data-color-scheme='no-preference: light; light: light; dark: dark;'
            data-icon='octicon-star'
            data-size='large'
            data-show-count='true'
            aria-label='Star CurlerRoo/CurlerRoo on GitHub'
          >
            &nbsp;&nbsp;Star on GitHub
          </GitHubButton>
        </div>
      </div>
    </div>
  );
};
