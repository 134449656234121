export const Footer = () => {
  return (
    <footer
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: 20,
      }}
    >
      CopyRight {new Date().getFullYear()} CurlerRoo. All Rights Reserved.
    </footer>
  )
}
