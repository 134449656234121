import { Header } from '../components/header'
import { Footer } from '../components/footer'

export const Screenshots = () => {
  return (
    <div
      style={{
        backgroundColor: '#E8E8E8',
        padding: '0 20px',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 40,
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Header />
        <div
          style={{
            maxWidth: 1200,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h1 style={{ fontSize: 48 }}>Screenshots</h1>
          <div style={{ border: '1px solid #888888', borderRadius: 10, overflow: 'hidden' }}>
            <img src='./screenshot-2.jpg' alt='screenshot' style={{ width: '100%' }} />
          </div>
          <div style={{ height: 40 }} />
          <div style={{ border: '1px solid #888888', borderRadius: 10, overflow: 'hidden' }}>
            <img src='./screenshot-1.jpg' alt='screenshot' style={{ width: '100%' }} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
