import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import logo from './logo.svg'
import { Home } from './pages/home'
import { About } from './pages/about'
import { Download } from './pages/download'
import { Screenshots } from './pages/screenshots'
import { Contact } from './pages/contact'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: 'download',
    element: <Download />,
  },
  {
    path: 'screenshots',
    element: <Screenshots />,
  },
  {
    path: 'contact',
    element: <Contact />,
  },
])

function App() {
  return <RouterProvider router={router}></RouterProvider>
}

export default App
